import actionTypes from 'utils/actionTypes';

export default {
  CREATE_LOAN_APPLICATION: actionTypes('CREATE_LOAN_APPLICATION'),
  REPAYMENT_DETAILS: actionTypes('REPAYMENT_DETAILS'),
  GET_LOANS: actionTypes('GET_LOANS'),
  UPDATE_WARD_DETAILS: actionTypes('UPDATE_WARD_DETAILS'),
  GET_LOAN_BYID: actionTypes('GET_LOAN_BYID'),
  EMPLOYMENT_DETAILS: actionTypes('EMPLOYMENT_DETAILS'),
  SAVE_BVN: actionTypes('SAVE_BVN'),
  ANSWER_LOAN_PARTNER: actionTypes('ANSWER_LOAN_PARTNER'),
  INITIALIZE_SIGNATURE: actionTypes('INITIALIZE_SIGNATURE'),
  VERIFY_SIGNATURE: actionTypes('VERIFY_SIGNATURE'),
  DELETE_LOAN: actionTypes('DELETE_LOAN'),
  RESET: actionTypes('RESET'),
  PAY_NEXT_INSTALLMENT: actionTypes('PAY_NEXT_INSTALLMENT'),
  PAY_OUTSTANDING: actionTypes('PAY_OUTSTANDING'),
  VALIDATE_BVN: actionTypes('VALIDATE_BVN'),
  FOREIGN_TUITION: actionTypes('FOREIGN_TUITION')
};
