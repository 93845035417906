import { combineReducers } from 'redux';
import authReducer from './auth';
import wardReducer from './ward';
import accountsReducer from './accounts';
import userReducer from './user';
import uploadReducer from './uploads';
import loanReducer from './loan';
import customReducer from './custom';
import loanPartnerReducer from './loanPartner';
import schoolReducer from './school';
import loanReceiptReducer from './mfb';
import zoropayReducer from './zoropay';

export default combineReducers({
  auth: authReducer,
  ward: wardReducer,
  accounts: accountsReducer,
  user: userReducer,
  upload: uploadReducer,
  loan: loanReducer,
  custom: customReducer,
  loanPartner: loanPartnerReducer,
  school: schoolReducer,
  loanReceipt: loanReceiptReducer,
  zoropay: zoropayReducer
});
