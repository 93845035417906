import actionTypes from 'utils/actionTypes';

export default {
  UNSIGN: actionTypes('UNSIGN'),
  GET_OTP: actionTypes('GET_OTP'),
  LOAN_LIST: actionTypes('LOAN_LIST'),
  LIST_USERS: actionTypes('LIST_USERS'),
  LOAN_COMMENT: actionTypes('LOAN_COMMENT'),
  LOAN_ACTIVITY: actionTypes('LOAN_ACTIVITY'),
  LOAN_APPROVED: actionTypes('LOAN_APPROVED'),
  LOAN_DISBURSE: actionTypes('LOAN_DISBURSE'),
  LOAN_DOCUMENT: actionTypes('LOAN_DOCUMENT'),
  LOAN_DECISION: actionTypes('LOAN_DECISION'),
  PHASE1_LOAN_DECISION: actionTypes('PHASE1_LOAN_DECISION')
};
