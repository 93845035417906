import loanReceiptActionTypes from './actionTypes';
import parseError from 'utils/ParseError';

const initialState = {
  receiptdata: null,
  loanReceipt: null,
  disburseData: null,
  document: null,
  decided: null,
  receiptloading: false,
  error: null,
  actionLoading: false,
  loan_repayment: null
};

export const loanReceiptReducer = (state = initialState, action) => {
  switch (action.type) {
    case loanReceiptActionTypes.LOAN_DOCUMENT.pending:
    case loanReceiptActionTypes.LOAN_REPAYMENT.pending:
    case loanReceiptActionTypes.LOAN_RECEIPT.pending:
    case loanReceiptActionTypes.LOAN_DECISION.pending:
    case loanReceiptActionTypes.LOAN_DISBURSE.pending:
    case loanReceiptActionTypes.LOAN_APPROVED.pending: {
      return {
        ...state,
        receiptloading: true,
        error: null
      };
    }
    case loanReceiptActionTypes.LOAN_REPAYMENT.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        loan_repayment: action.payload.data.data.repayments
      };
    }
    case loanReceiptActionTypes.LOAN_DECISION.fulfilled: {
      return {
        ...state,
        receiptloading: false,
        error: null,
        decided: action.payload.data.data
      };
    }
    case loanReceiptActionTypes.LOAN_RECEIPT.fulfilled: {
      return {
        ...state,
        receiptloading: false,
        error: null,
        receiptdata: action.payload.data.data
      };
    }
    case loanReceiptActionTypes.LOAN_APPROVED.fulfilled: {
      return {
        ...state,
        receiptloading: false,
        error: null,
        loanReceipt: action.payload.data.data
      };
    }
    case loanReceiptActionTypes.LOAN_DOCUMENT.fulfilled: {
      return {
        ...state,
        receiptloading: false,
        error: null,
        document: action.payload.data.data.upload
      };
    }
    case loanReceiptActionTypes.LOAN_DISBURSE.fulfilled: {
      return {
        ...state,
        receiptloading: false,
        error: null,
        disburseData: action.payload.data.data.disbursements
      };
    }
    case loanReceiptActionTypes.LOAN_REPAYMENT.rejected:
    case loanReceiptActionTypes.LOAN_DOCUMENT.rejected:
    case loanReceiptActionTypes.LOAN_RECEIPT.rejected:
    case loanReceiptActionTypes.LOAN_DECISION.rejected:
    case loanReceiptActionTypes.LOAN_DISBURSE.rejected:
    case loanReceiptActionTypes.LOAN_APPROVED.rejected: {
      return {
        ...state,
        receiptloading: false,
        error: parseError(action.payload)
      };
    }
    default:
      return state;
  }
};
export default loanReceiptReducer;
