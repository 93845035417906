const APP_VERSION_STORAGE_KEY = 'pwa_version';

export function updateApp(response) {
  const headers = response.headers;
  const CURRENT_PWA_VERSION = headers['pwa-version'];
  const INITIAL_PWA_VERSION = getAppVersionFromStorage();

  if (
    response.data.message !== 'OTP verified successfully' &&
    isUpdate(CURRENT_PWA_VERSION, INITIAL_PWA_VERSION)
  ) {
    saveAppVersion(CURRENT_PWA_VERSION);
    window.location.reload(true);
  }
}

function saveAppVersion(version) {
  localStorage.setItem(APP_VERSION_STORAGE_KEY, version);
}

function getAppVersionFromStorage() {
  return localStorage.getItem(APP_VERSION_STORAGE_KEY);
}

function isUpdate(currentVersion, initialVersion) {
  if (!currentVersion) {
    return false;
  }
  return currentVersion !== initialVersion;
}
