import loanActionTypes from './actionTypes';
import parseError from 'utils/ParseError';

const initialState = {
  data: null,
  loading: false,
  error: null,
  actionLoading: false,
  loans: null,
  foreignLoan: null,
  loan: null
};

export const loanReducer = (state = initialState, action) => {
  switch (action.type) {
    case loanActionTypes.RESET: {
      return initialState;
    }
    case loanActionTypes.CREATE_LOAN_APPLICATION.pending:
    case loanActionTypes.FOREIGN_TUITION.pending:
    case loanActionTypes.UPDATE_WARD_DETAILS.pending:
    case loanActionTypes.REPAYMENT_DETAILS.pending:
    case loanActionTypes.GET_LOAN_BYID.pending:
    case loanActionTypes.EMPLOYMENT_DETAILS.pending:
    case loanActionTypes.SAVE_BVN.pending:
    case loanActionTypes.ANSWER_LOAN_PARTNER.pending:
    case loanActionTypes.INITIALIZE_SIGNATURE.pending:
    case loanActionTypes.VERIFY_SIGNATURE.pending:
    case loanActionTypes.DELETE_LOAN.pending:
    case loanActionTypes.PAY_NEXT_INSTALLMENT.pending:
    case loanActionTypes.VALIDATE_BVN.pending:
    case loanActionTypes.PAY_OUTSTANDING.pending: {
      return {
        ...state,
        actionLoading: true,
        error: null
      };
    }
    case loanActionTypes.FOREIGN_TUITION.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        foreignLoan: action.payload.data.data.masters_submissions
      };
    }

    case loanActionTypes.INITIALIZE_SIGNATURE.fulfilled:
    case loanActionTypes.VERIFY_SIGNATURE.fulfilled:
    case loanActionTypes.DELETE_LOAN.fulfilled:
    case loanActionTypes.PAY_NEXT_INSTALLMENT.fulfilled:
    case loanActionTypes.VALIDATE_BVN.fulfilled:
    case loanActionTypes.PAY_OUTSTANDING.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null
      };
    }
    case loanActionTypes.GET_LOAN_BYID.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        loan: action.payload.data.data
      };
    }
    case loanActionTypes.CREATE_LOAN_APPLICATION.fulfilled:
    case loanActionTypes.UPDATE_WARD_DETAILS.fulfilled:
    case loanActionTypes.REPAYMENT_DETAILS.fulfilled:
    case loanActionTypes.EMPLOYMENT_DETAILS.fulfilled:
    case loanActionTypes.SAVE_BVN.fulfilled:
    case loanActionTypes.ANSWER_LOAN_PARTNER.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        data: action.payload.data.data
      };
    }
    case loanActionTypes.CREATE_LOAN_APPLICATION.rejected:
    case loanActionTypes.FOREIGN_TUITION.rejected:
    case loanActionTypes.UPDATE_WARD_DETAILS.rejected:
    case loanActionTypes.REPAYMENT_DETAILS.rejected:
    case loanActionTypes.GET_LOAN_BYID.rejected:
    case loanActionTypes.EMPLOYMENT_DETAILS.rejected:
    case loanActionTypes.SAVE_BVN.rejected:
    case loanActionTypes.ANSWER_LOAN_PARTNER.rejected:
    case loanActionTypes.INITIALIZE_SIGNATURE.rejected:
    case loanActionTypes.VERIFY_SIGNATURE.rejected:
    case loanActionTypes.DELETE_LOAN.rejected:
    case loanActionTypes.PAY_NEXT_INSTALLMENT.rejected:
    case loanActionTypes.VALIDATE_BVN.rejected:
    case loanActionTypes.PAY_OUTSTANDING.rejected: {
      return {
        ...state,
        actionLoading: false,
        error: parseError(action.payload)
      };
    }
    case loanActionTypes.GET_LOANS.pending: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case loanActionTypes.GET_LOANS.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null,
        loans: action.payload.data.data
      };
    }
    case loanActionTypes.GET_LOANS.rejected: {
      return {
        ...state,
        loading: false,
        error: parseError(action.payload)
      };
    }
    default:
      return state;
  }
};

export default loanReducer;
