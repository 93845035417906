import styled, { css } from 'styled-components';

// STUDENT VERIFICATION COMPONENTS
export const Heading = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  color: #291d4a;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Verified = styled.p`
  font-size: 28px;
  font-weight: 400;
  text-align: left;
  margin-top: 10px;
`;
export const Verifiedno = styled.span`
  font-family: Poppins;
  font-weight: 700;
  color: #1949d8;
`;

export const SubHeader = styled.p`
  font-weight: 600;
  font-size: 28px;
  color: #291d4a;
  ${(props) =>
    props.alignCenter &&
    css`
      text-align: center;
    `};
  ${(props) =>
    props.modalText &&
    css`
      font-size: 21px;
    `}
  ${(props) =>
    props.height &&
    css`
      height: 84px;
    `};
  ${(props) =>
    props.width &&
    css`
      width: 362px;
    `};
  @media (max-width: 720px) {
    text-align: center;
  }
`;
// STUDENT OVERVIEW
export const Number = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  color: #291d4a;
`;
export const JustifyContainer = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.justify ? props.justify : 'space-around'};
`;

// DENY STUDENT MODAL
export const Modalheader = styled.h2`
  // font-weight: bold;
  font-size: 22px;
  color: #1949d8 !important;
  margin-bottom: 14px;
`;

export const Text = styled.p`
  color: ${(props) => (props.color ? props.color : '#555555')};
  font-size: ${(props) => (props.fontsize ? props.fontsize : '21px')};
  margin-bottom: ${(props) => (props.margin ? props.margin : '35px')};
  ${(props) =>
    props.bold &&
    css`
      font-weight: 600 !important;
    `};
  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}
  ${(props) =>
    props.padding &&
    css`
      padding: 0 20px;
    `}
`;

export const Commentinput = styled.textarea`
  width: 100%;
  height: 150px;
  border: 2px solid #1949d8;
  padding:10px;
  border-radius:12px
  box-sizing: border-box;
  margin-bottom: 15px;
  resize: none;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

// QUICK ACTIONS
export const QuickActions = styled.div`
  min-width: 309px;
  width: 345px;
  height: 290px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 17px;
  padding: 27px;
  margin-right: 0;
  margin-left: 44px;
  margin-top: 30px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 14.93px;
  }
  @media only screen and (max-width: 769px) {
    margin-left: 0;
    max-width: 100%;
    width: 100%;
  }
`;
