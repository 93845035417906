import zoropayActionTypes from './actionTypes';
import parseError from 'utils/ParseError';

const initialState = {
  receiptdata: null,
  loanReceipt: null,
  disburseData: null,
  activityLog: null,
  listOfUsers: null,
  document: null,
  otps: null,
  decided: null,
  zoropay_comments: null,
  receiptloading: false,
  error: null,
  actionLoading: false
};

export const loanReceiptReducer = (state = initialState, action) => {
  switch (action.type) {
    case zoropayActionTypes.UNSIGN.pending:
    case zoropayActionTypes.GET_OTP.pending:
    case zoropayActionTypes.LOAN_ACTIVITY.pending:
    case zoropayActionTypes.LOAN_COMMENT.pending:
    case zoropayActionTypes.LOAN_LIST.pending:
    case zoropayActionTypes.LIST_USERS.pending:
    case zoropayActionTypes.LOAN_DOCUMENT.pending:
    case zoropayActionTypes.LOAN_DECISION.pending:
    case zoropayActionTypes.PHASE1_LOAN_DECISION.pending:
    case zoropayActionTypes.LOAN_DISBURSE.pending:
    case zoropayActionTypes.LOAN_APPROVED.pending: {
      return {
        ...state,
        receiptloading: true,
        error: null
      };
    }
    case zoropayActionTypes.LOAN_DECISION.fulfilled:
    case zoropayActionTypes.PHASE1_LOAN_DECISION.fulfilled: {
      return {
        ...state,
        receiptloading: false,
        error: null,
        decided: action.payload.data.data
      };
    }
    case zoropayActionTypes.GET_OTP.fulfilled: {
      return {
        ...state,
        receiptloading: false,
        error: null,
        otps: action.payload.data.data.otps
      };
    }
    case zoropayActionTypes.LOAN_COMMENT.fulfilled:
    case zoropayActionTypes.LOAN_ACTIVITY.fulfilled: {
      return {
        ...state,
        receiptloading: false,
        error: null,
        activityLog: action.payload.data.data.activity_log
      };
    }
    case zoropayActionTypes.LOAN_LIST.fulfilled: {
      return {
        ...state,
        receiptloading: false,
        error: null,
        receiptdata: action.payload.data.data
      };
    }

    case zoropayActionTypes.LIST_USERS.fulfilled: {
      return {
        ...state,
        receiptloading: false,
        error: null,
        listOfUsers: action.payload.data.data.users
      };
    }

    case zoropayActionTypes.UNSIGN.fulfilled:
    case zoropayActionTypes.LOAN_APPROVED.fulfilled: {
      return {
        ...state,
        receiptloading: false,
        error: null,
        loanReceipt: action.payload.data.data
      };
    }
    case zoropayActionTypes.LOAN_DOCUMENT.fulfilled: {
      return {
        ...state,
        receiptloading: false,
        error: null,
        document: action.payload.data.data.upload
      };
    }
    case zoropayActionTypes.LOAN_DISBURSE.fulfilled: {
      return {
        ...state,
        receiptloading: false,
        error: null,
        disburseData: action.payload.data.data.disbursements
      };
    }
    case zoropayActionTypes.UNSIGN.rejected:
    case zoropayActionTypes.GET_OTP.rejected:
    case zoropayActionTypes.LOAN_LIST.rejected:
    case zoropayActionTypes.LIST_USERS.rejected:
    case zoropayActionTypes.LOAN_DOCUMENT.rejected:
    case zoropayActionTypes.LOAN_COMMENT.rejected:
    case zoropayActionTypes.LOAN_ACTIVITY.rejected:
    case zoropayActionTypes.LOAN_DECISION.rejected:
    case zoropayActionTypes.PHASE1_LOAN_DECISION.rejected:
    case zoropayActionTypes.LOAN_DISBURSE.rejected:
    case zoropayActionTypes.LOAN_APPROVED.rejected: {
      return {
        ...state,
        receiptloading: false,
        error: parseError(action.payload)
      };
    }
    default:
      return state;
  }
};
export default loanReceiptReducer;
