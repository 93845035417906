import customActionTypes from './actionTypes';
import parseError from 'utils/ParseError';

const initialState = {
  data: null,
  loading: false,
  actionLoading: false,
  error: null,
  bvn: null
};

export const customReducer = (state = initialState, action) => {
  switch (action.type) {
    case customActionTypes.GET_CUSTOM.pending: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case customActionTypes.GET_CUSTOM.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data.data
      };
    }

    case customActionTypes.GET_CUSTOM.rejected: {
      return {
        ...state,
        loading: false,
        error: parseError(action.payload)
      };
    }

    case customActionTypes.FIND_BVN.pending: {
      return {
        ...state,
        actionLoading: true,
        error: null
      };
    }

    case customActionTypes.FIND_BVN.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        bvn: action.payload.data.data
      };
    }

    case customActionTypes.FIND_BVN.rejected: {
      return {
        ...state,
        actionLoading: false,
        error: parseError(action.payload)
      };
    }

    default:
      return state;
  }
};

export default customReducer;
