import React from 'react';
import Modal from 'reuseables/Modal';
import Button from 'reuseables/button';
import { JustifyContainer } from 'pages/zoropayDashboard/components/TextComponents';

const SessionModal = ({ isOpen, onClose, logOut, stayActive }) => {
  return (
    <>
      <Modal
        children={
          <>
            <h3
              style={{
                fontSize: '20px',
                textAlign: 'center',
                color: '#3F3356',
                marginBottom: '36px'
              }}
            >
              You Have Been Idle
            </h3>

            <div>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '16px',
                  color: '#3F3356'
                }}
              >
                Your current session is about to expire. Do you want to extend
                your current session
              </p>
              <JustifyContainer
                style={{ width: '60%', margin: '0  auto' }}
                round
              >
                <Button
                  text="No"
                  round
                  colo={'#fff'}
                  width="120px"
                  onClick={logOut}
                  background={'#d92f2f'}
                />
                <Button text="Yes" width="120px" onClick={stayActive} round />
              </JustifyContainer>
            </div>
          </>
        }
        onClose={onClose}
        isOpen={isOpen}
        width={394}
      />
    </>
  );
};

export default SessionModal;
