import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import * as Pages from './pageloader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import './assets/css/main.css';
import './assets/css/reset.css';
import IdleTimerContainer from './SessionTimeout';

const ToastConfig = {
  className: 'toast__container',
  bodyClassName: 'toast__body',
  closeOnClick: true,
  pauseOnHover: false,
  position: 'top-left'
};

// configure global toaster
toast.configure(ToastConfig);

const App = () => {
  return (
    <Router>
      <IdleTimerContainer />
      <Switch>
        <Route exact path="/">
          <Redirect to="/zoropay-login" />
        </Route>
        {/* ZOROPAY */}
        <Route path="/zoropay" component={Pages.ZOROPAY} />
        <Route path="/zoropay-login" component={Pages.ZOROPAYLogIn} />
        <Route
          exact
          path="/zoropay-verifyotp"
          component={Pages.ZOROPAYVerifyOTP}
        />
        <Route
          exact
          path="/zoropay-passwordreset"
          component={Pages.ZOROPAYPasswordReset}
        />
        <Route
          exact
          path="/zoropay-forgotpassword"
          component={Pages.ZOROPAYForgotPassword}
        />
        {/* END OF ZOROPAY DASHBOARD */}
        <Route
          exact
          path="/termsandconditions"
          component={Pages.TermsAndConditions}
        />
        <Route exact path="/privacy" component={Pages.Privacy} />
      </Switch>
    </Router>
  );
};

export default App;
