import uploadActionTypes from './actionTypes';
import parseError from 'utils/ParseError';

const initialState = {
  data: null,
  fieldsData: null,
  loading: false,
  error: null
};

export const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case uploadActionTypes.UPLOAD_DOCUMENTS.pending: {
      return {
        ...state,
        error: null
      };
    }
    case uploadActionTypes.UPLOAD_DOCUMENTS.fulfilled: {
      return {
        ...state,
        error: null
      };
    }
    case uploadActionTypes.UPLOAD_DOCUMENTS.rejected: {
      return {
        ...state,
        error: parseError(action.payload)
      };
    }
    case uploadActionTypes.GET_UPLOADS.pending:
    case uploadActionTypes.DOCUMENT_FIELDS.pending: {
      return {
        ...state,
        error: null
      };
    }
    case uploadActionTypes.GET_UPLOADS.fulfilled: {
      return {
        ...state,
        error: null,
        data: action.payload.data.data
      };
    }
    case uploadActionTypes.DOCUMENT_FIELDS.fulfilled: {
      return {
        ...state,
        error: null,
        fieldsData: action.payload.data?.data.documents
      };
    }
    case uploadActionTypes.GET_UPLOADS.rejected:
    case uploadActionTypes.DOCUMENT_FIELDS.rejected: {
      return {
        ...state,
        error: parseError(action.payload)
      };
    }
    default:
      return state;
  }
};

export default uploadReducer;
