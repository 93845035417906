import loadable from '@loadable/component';
import Preloader from './preloader';

export const Home = loadable(() => import('pages/home'), {
  LoadingComponent: Preloader
});

export const TermsAndConditions = loadable(
  () => import('pages/home/terms&conditions'),
  {
    LoadingComponent: Preloader
  }
);

export const Privacy = loadable(() => import('pages/home/privacy'), {
  LoadingComponent: Preloader
});
export const PageNotFound = loadable(() => import('pages/notfound/404'), {
  LoadingComponent: Preloader
});
// ZOROPAY
export const ZOROPAY = loadable(() => import('pages/zoropayDashboard'), {
  LoadingComponent: Preloader
});
export const ZOROPAYLogIn = loadable(
  () => import('pages/zoropayDashboard/SignIn'),
  {
    LoadingComponent: Preloader
  }
);
export const ZOROPAYVerifyOTP = loadable(
  () => import('pages/zoropayDashboard/verifyOtp'),
  {
    LoadingComponent: Preloader
  }
);
export const ZOROPAYForgotPassword = loadable(() =>
  import('pages/zoropayDashboard/forgotPassword')
);

export const ZOROPAYPasswordReset = loadable(() =>
  import('pages/zoropayDashboard/passwordReset')
);
// END OF ZOROPAY
//for more info about use of loadable-components -> https://github.com/gregberge/loadable-components
