import actionTypes from 'utils/actionTypes';

export default {
  LOAN_RECEIPT: actionTypes('LOAN_RECEIPT'),
  LOAN_APPROVED: actionTypes('LOAN_APPROVED'),
  LOAN_DISBURSE: actionTypes('LOAN_DISBURSE'),
  LOAN_REPAYMENT: actionTypes('LOAN_REPAYMENT'),
  LOAN_DOCUMENT: actionTypes('LOAN_DOCUMENT'),
  LOAN_DECISION: actionTypes('LOAN_DECISION')
};
